<template>
  <Component
    :is="to ? NuxtLink : tag"
    :to="to"
    :target="target !== 'default' ? target : isExternal ? '_blank' : null"
    :rel="isExternal ? rel : null"
    :type="type === 'submit' ? 'submit' : 'button'"
    :aria-label="label"
    :title="label"
    :class="[
      ...(appearance || []),
      event && `plausible-event-name=${event}`,
      icon
        ? !text
          ? 'has-icon-only'
          : 'has-icon'
        : '',
      animation ? `ani-${animation}` : undefined,
      { button }
    ]"
    @click="action"
    class="c-link"
  >
    <template v-if="text">
      <span v-if="typeof text === 'string'" class="c-link-text" v-text="text" />
      <span v-for="textItem in text" v-else-if="Array.isArray(text)" :key="textItem" class="c-link-text" v-text="textItem" />
    </template>
    <CAtomsIcon
      v-if="icon"
      class="c-link-icon"
      :class="(iconFirst || iconLast) ? `icon-${iconFirst ? 'first' : 'last'}` : text ? 'icon-first' : ''"
      :icon="icon"
    />
    <slot />
  </Component>
</template>

<script lang="ts" setup>
const props = defineProps({
  to: { type: [String, Object], default: undefined },
  type: { type: String, default: '' },
  label: { type: String, default: '' },
  text: { type: [String, Array], default: undefined },
  event: { type: String, default: undefined },
  animation: { type: String, default: undefined },
  appearance: { type: Array, default: () => [] },
  button: { type: Boolean, default: false },
  tag: { type: String, default: 'button' },
  target: { type: String, default: 'default' },
  rel: { type: String, default: 'noopener' },
  icon: { type: String as CznIcon, default: undefined },
  iconFirst: { type: Boolean, default: false },
  iconLast: { type: Boolean, default: false },
  action: { type: Function, default: null }
})
const NuxtLink = resolveComponent('LazyNuxtLink')
const isExternal = computed(
  () =>
    !!props.to &&
    typeof props.to === 'string' &&
    /^(http|mailto|tel|data).*/.test(props.to)
)
</script>

<style lang="scss">
.c-link {
  position: relative;

  width: fit-content;
  font-size: 1em;
  // overflow: hidden;
  font-weight: bold;
  display: inline-flex;
  align-items: center;
  transition: czn.transition(var(--transition), (color, filter));

  &.ani-add {
    &.active {
      .c-link-icon {
        transform: rotate(180 + 45deg);
      }
    }
  }

  &.secondary {
    color: var(--secondary)
  }

  &-text {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    z-index: 10;
  }

  &-icon {
    order: -1;
    color: inherit;
    // transform: translateY(2px);
    margin-bottom: var(--link-icon-offset);
    z-index: 10;

    font-size: 1.25em;
    transition: czn.transition(var(--transition), (transform));

    &.icon {
      &-first {
        order: -1;
        margin-right: 0.5em;
        margin-left: -0.25em;
      }
      &-last {
        order: 1;
        margin-left: 0.5em;
        margin-right: -0.25em;
      }
    }

  }

  &.meta,
  &.has-icon-only {
    .c-link-icon {
      transform: none;
    }
  }
  &.has-icon-only {
    .c-link-icon {
      margin: 0;
    }
  }

  // &.has-icon {
  //   .c-link-icon {
  //     margin-right: 0.5em;
  //   }
  // }

  // &::before {
  //   background: currentColor;
  //   position: absolute;
  //   inset: 0 25%;
  //   content: '';
  //   transform: skew(-14deg) translateX(-150%);
  //   transition: czn.transition(0.3s, (transform));
  // }

  &:disabled,
  &[disabled],
  &.disabled {
    opacity: 0.6;
    filter: saturate(0);
    cursor: not-allowed;

    &,
    &::before,
    &::after {
      animation: none !important;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &.active {
  }
}
</style>
a
